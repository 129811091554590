<div class="m-5 d-flex gap-2">

  <button class="primary-small-btn info-tooltip" placement="right"
    [ngbTooltip]="unauthorized==true ?  'You are not authorized.':''"
    [ngClass]="unauthorized==true ? 'unauthorized-action':''" (click)="Onclick()">Accept</button>

  <div [ngClass]="unauthorized==true ? 'unauthorized-cursor':''" placement="right"
    [ngbTooltip]="unauthorized==true ?  'You are not authorized.':''">
    <button class="primary-small-btn info-tooltip" [ngClass]="unauthorized==true ? 'unauthorized-action':''"
      (click)="Onclick()">Reject</button>
  </div>


</div>
<div>
  <div class="card" style="overflow: scroll;">
    <svg id="gantt" style="min-height: 100%"></svg>
    
  </div>
  <div class="mx-auto mt-3 btn-group" role="group">
    <button type="button" class="btn btn-sm btn-light" (click)="changeView('Quarter Day')">Quarter Day</button>
    <button type="button" class="btn btn-sm btn-light" (click)="changeView('Half Day')">Half Day</button>
    <button type="button" class="btn btn-sm btn-light" (click)="changeView('Day')">Day</button>
    <button type="button" class="btn btn-sm btn-light" (click)="changeView('Week')">Week</button>
    <button type="button" class="btn btn-sm btn-light" (click)="changeView('Month')">Month</button>
    <!-- <button type="button" class="btn btn-sm btn-light" (click)="changeView('Year')">Year</button> -->
  </div>
</div>

<p class="title">Heading 1</p>
<p class="heading-1">Heading 1</p>
<p class="heading-2">Heading 1</p>
<p class="heading-3">Heading 1</p>
<p class="body-text-1">Heading 1</p>
<p class="body-text-2">Heading 1</p>
<p class="body-text-3">Heading 1</p>
<p class="caption">Heading 1</p>

<br />

<div class="m-3">

  <mat-radio-group class="custom-radio">
    <mat-radio-button value="1">Option 1</mat-radio-button>
    <mat-radio-button value="2" selected>Option 2</mat-radio-button>
  </mat-radio-group>

  <br><br>

  <mat-form-field class="custom-dropdown skill">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0" title="Select Skills">Select Skills</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills" [title]="option.viewValue">{{ option.viewValue
        }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="custom-dropdown experience">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0" title="Select Exp.">Select Exp.</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills" [title]="option.viewValue">{{ option.viewValue
        }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="custom-dropdown sort">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0" title="Sort by">Sort by</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills" [title]="option.viewValue">{{ option.viewValue
        }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="custom-dropdown all">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0" title="All">All</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills" [title]="option.viewValue">{{ option.viewValue
        }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
    <mat-label>Search here...</mat-label>
    <input matInput type="text">
    <button matSuffix>
      <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
        <path id="Path_690" data-name="Path 690"
          d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
          transform="translate(0)" fill="#464646" />
      </svg>
    </button>
  </mat-form-field>

  <br><br>

  <div class="dropdown-search">
    <mat-form-field class="custom-dropdown">
      <mat-select disableRipple [(value)]="selectedSkills">
        <mat-option value="0" title="Requirements">Requirements</mat-option>
        <mat-option [value]="option.value" *ngFor="let option of skills" [title]="option.viewValue">{{ option.viewValue
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
      <mat-label>Search</mat-label>
      <input matInput type="text">
      <button matSuffix>
        <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
          <path id="Path_690" data-name="Path 690"
            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
            transform="translate(0)" fill="#464646" />
        </svg>
      </button>
    </mat-form-field>
  </div>

  <br>

  <div class="field-box">
    <label for="custom-input" class="custom-label">First Name</label>
    <mat-form-field class="custom-input" floatLabel="never" floatPlaceholder="never">
      <mat-label>Type here</mat-label>
      <input matInput id="custom-input" placeholder="Type here">
    </mat-form-field>
  </div>

  <br>


  <br>

  <div class="field-box">
    <label for="custom-input" class="custom-label">PassWord</label>
    <mat-form-field hideRequiredMarker name="mat-form-field-password" id="mat-form-field-password" class="custom-input"
      floatLabel="never" floatPlaceholder="never">
      <input matInput required [type]="hidePassword ? 'password' : 'text'" name="txPassword" id="txPassword"
        placeholder="Ex. ******" formControlName="txPassword" />
      <mat-icon matSuffix (click)="hidePassword = !hidePassword" name="password-visibility-eye"
        id="password-visibility-eye" class="d-flex justify-content-center align-items-center h-100 visibility-eye">{{
        hidePassword ?
        "visibility_off" : "visibility" }}
      </mat-icon>
    </mat-form-field>
  </div>

  <br>

  <mat-form-field class="custom-input" floatLabel="never" floatPlaceholder="never">
    <mat-label>Type here</mat-label>
    <input matInput placeholder="Type here">
  </mat-form-field>

  <br>

  <mat-form-field class="custom-input textarea" floatLabel="never" floatPlaceholder="never">
    <mat-label>Type here</mat-label>
    <textarea matInput placeholder="Type here"></textarea>
  </mat-form-field>

  <br>

  <mat-form-field class="custom-input" floatLabel="never" floatPlaceholder="never">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" readonly>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <br>

  <mat-form-field class="custom-dropdown form">
    <mat-select disableRipple [(value)]="selectedSkills">
      <mat-option value="0" title="All">All</mat-option>
      <mat-option [value]="option.value" *ngFor="let option of skills" [title]="option.viewValue">{{ option.viewValue
        }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-icon-button [matMenuTriggerFor]="menu" class="custom-dropdown custom-menu">
    <svg class="icon" id="more_vert_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
      viewBox="0 0 24 24">
      <path id="Path_14241" data-name="Path 14241" d="M0,0H24V24H0Z" fill="none" />
      <path id="Path_14242" data-name="Path 14242"
        d="M12,8a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,12,16Z"
        fill="#717682" />
    </svg>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <span>Propose a new time</span>
    </button>
    <button mat-menu-item>
      <span>Cancel Interview</span>
    </button>
  </mat-menu>

  <br>

  <mat-checkbox class="custom-checkbox">Check me!</mat-checkbox>

  <br><br>

  <div class="tab-container">
    <mat-form-field class="custom-input custom-search-input" floatLabel="never" floatPlaceholder="never">
      <mat-label>Search by requirement name</mat-label>
      <input matInput type="text">
      <button matSuffix>
        <svg id="search_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="Path_689" data-name="Path 689" d="M0,0H20V20H0Z" fill="none" />
          <path id="Path_690" data-name="Path 690"
            d="M12.567,11.42h-.6l-.214-.207a4.989,4.989,0,1,0-.536.536l.207.214v.6l3.253,3.253a.806.806,0,1,0,1.14-1.141Zm-4.592,0a3.445,3.445,0,1,1,3.444-3.445A3.44,3.44,0,0,1,7.975,11.42Z"
            transform="translate(0)" fill="#464646" />
        </svg>
      </button>
    </mat-form-field>
    <mat-tab-group mat-align-tabs="start" class="custom-tab-group">
      <mat-tab label="First">Content 1</mat-tab>
      <mat-tab label="Second">Content 2</mat-tab>
      <mat-tab disabled>
        <ng-template mat-tab-label>
          <button class="borderless-btn with-icon">
            <span class="btn-content">
              <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                <path id="Path_14128" data-name="Path 14128"
                  d="M18,13H13v5a1,1,0,0,1-2,0V13H6a1,1,0,0,1,0-2h5V6a1,1,0,0,1,2,0v5h5a1,1,0,0,1,0,2Z"
                  transform="translate(-4.5 -4.5)" fill="#595e6c" stroke="rgba(0,0,0,0)" stroke-width="1" />
              </svg>
              Add New Talent
            </span>
          </button>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>


</div>

<br><br>

<button class="primary-btn">ABCD</button>

<br />

<button class="primary-btn" disabled>ABCD</button>

<br /><br />

<button class="primary-small-btn">ABCD</button>

<br />

<button class="primary-small-btn" disabled>ABCD</button>

<br /><br />

<button class="secondary-btn">ABCD</button>

<br />

<button class="secondary-btn" disabled>ABCD</button>

<br /><br />

<button class="secondary-small-btn">ABCD</button>

<br />

<button class="secondary-small-btn" disabled>ABCD</button>

<br /><br />

<button class="primary-btn with-icon">
  <span class="btn-content">
    ABCD
    <svg class="icon right" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_14090" data-name="Path 14090"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
        transform="translate(-2 -2)" fill="#8a8d97" />
    </svg>
  </span>
</button>

<br />

<button class="primary-btn with-icon" disabled>
  <span class="btn-content">
    ABCD
    <svg class="icon right" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_14090" data-name="Path 14090"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
        transform="translate(-2 -2)" fill="#8a8d97" />
    </svg>
  </span>
</button>

<br /><br />

<button class="secondary-btn with-icon">
  <span class="btn-content">
    <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_14090" data-name="Path 14090"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
        transform="translate(-2 -2)" fill="#8a8d97" />
    </svg>
    ABCD
  </span>
</button>

<br />

<button class="secondary-btn with-icon" disabled>
  <span class="btn-content">
    <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_14090" data-name="Path 14090"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
        transform="translate(-2 -2)" fill="#8a8d97" />
    </svg>
    ABCD
  </span>
</button>

<br /><br />

<button class="gray-small-btn">ABCD</button>

<br />

<button class="gray-small-btn" disabled>ABCD</button>

<br />

<button class="gray-small-btn with-icon">
  <span class="btn-content">
    <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_14090" data-name="Path 14090"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
        transform="translate(-2 -2)" fill="#8a8d97" />
    </svg>
    ABCD
  </span>
</button>

<br />

<button class="gray-small-btn with-icon" disabled>
  <span class="btn-content">
    <svg class="icon left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_14090" data-name="Path 14090"
        d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm3.5-9A1.5,1.5,0,1,0,14,9.5,1.5,1.5,0,0,0,15.5,11Zm-7,0A1.5,1.5,0,1,0,7,9.5,1.5,1.5,0,0,0,8.5,11ZM12,17.5a5.489,5.489,0,0,0,4.75-2.75.5.5,0,0,0-.44-.75H7.69a.506.506,0,0,0-.44.75A5.489,5.489,0,0,0,12,17.5Z"
        transform="translate(-2 -2)" fill="#8a8d97" />
    </svg>
    ABCD
  </span>
</button>

<br><br>

<button class="talent-button">
  <span class="btn-content">
    <span class="icon-background left">
      <svg class="icon" id="groups_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24">
        <rect id="Rectangle_3442" data-name="Rectangle 3442" width="24" height="24" fill="none" />
        <g id="Group_4968" data-name="Group 4968" transform="translate(0 6)">
          <path id="Path_14143" data-name="Path 14143"
            d="M12,12.75a10.611,10.611,0,0,1,4.24.9A2.984,2.984,0,0,1,18,16.38V17a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1v-.61a2.963,2.963,0,0,1,1.76-2.73A10.44,10.44,0,0,1,12,12.75ZM4,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,4,13Zm1.13,1.1A6.983,6.983,0,0,0,4,14a6.95,6.95,0,0,0-2.78.58A2.011,2.011,0,0,0,0,16.43V17a1,1,0,0,0,1,1H4.5V16.39A4.5,4.5,0,0,1,5.13,14.1ZM20,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,20,13Zm4,3.43a2.011,2.011,0,0,0-1.22-1.85,6.8,6.8,0,0,0-3.91-.48,4.5,4.5,0,0,1,.63,2.29V18H23a1,1,0,0,0,1-1ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z"
            transform="translate(0 -6)" fill="#8a8d97" />
        </g>
      </svg>
    </span>
    No Talent Added
  </span>
</button>

<br />

<button class="talent-button active">
  <span class="btn-content">
    <span class="icon-background left">
      <svg class="icon" id="groups_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
        viewBox="0 0 24 24">
        <rect id="Rectangle_3442" data-name="Rectangle 3442" width="24" height="24" fill="none" />
        <g id="Group_4968" data-name="Group 4968" transform="translate(0 6)">
          <path id="Path_14143" data-name="Path 14143"
            d="M12,12.75a10.611,10.611,0,0,1,4.24.9A2.984,2.984,0,0,1,18,16.38V17a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1v-.61a2.963,2.963,0,0,1,1.76-2.73A10.44,10.44,0,0,1,12,12.75ZM4,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,4,13Zm1.13,1.1A6.983,6.983,0,0,0,4,14a6.95,6.95,0,0,0-2.78.58A2.011,2.011,0,0,0,0,16.43V17a1,1,0,0,0,1,1H4.5V16.39A4.5,4.5,0,0,1,5.13,14.1ZM20,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,20,13Zm4,3.43a2.011,2.011,0,0,0-1.22-1.85,6.8,6.8,0,0,0-3.91-.48,4.5,4.5,0,0,1,.63,2.29V18H23a1,1,0,0,0,1-1ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z"
            transform="translate(0 -6)" fill="#8a8d97" />
        </g>
      </svg>
    </span>
    1 Talent
  </span>
</button>

<br><br>

<button type="button" class="m-3" (click)="APIcalling()">
  API
</button>

<div>
  <rg-gauge-chart style="padding: 10px;" [canvasWidth]="canvasWidth" [needleValue]=needleValue [bottomLabel]=needleValue
    [centralLabel]="centralLabel" [options]="options">
  </rg-gauge-chart>
  <!-- [options.arcDelimiters]="50"  How to change this value in view-->
  <button (click)="change()">change needle value</button>
</div><br><br>


<p> {{ description | emojify }} </p>
<p> {{ description1 | emojify }} </p>
<p> {{ description2 | emojify }} </p>
<p> {{ description3 | emojify }} </p>
<p> {{ description4 | emojify }} </p>
<p> {{ description5 | emojify }} </p>

<div>
  <span [innerHTML]="formatMessage(chatMessage.text | emojify)"></span>
</div>


<div class="col col-lg-4 order-2 order-md-1" #contentToExport>
  <address class="invoice-inner-text">
    <strong class="heading-text-invoice">TEKSUN INC</strong><br>
    16192 Coastal Highway<br>
    Lewes, DE 19958 US<br>
    (415) 851-7851<br>
    accounts@teksun.com
  </address>
  <div>
    <table>
      <tr>
        <th>Name</th>
        <th>Salary</th>
      </tr>
      <tr>
        <td>Ramesh Raman</td>
        <td>5000</td>
      </tr>

      <tr>
        <td>Shabbir Hussein</td>
        <td>7000</td>
      </tr>
    </table>
  </div>
</div>









<ngx-slider
  [(value)]="value"
  (valueChange)="generateFirstValue();"
  [options]="options"
></ngx-slider>
<div>
{{ value }}
</div>

<!-- <ngx-slider [(value)]="value" [options]="options"></ngx-slider> -->


<!-- <button *ngIf="!showTextarea" (click)="toggleTextarea()" style="border:2px solid black;">Toggle Textarea</button>
<textarea *ngIf="showTextarea" [(ngModel)]="textAreaContent" style="border:2px solid black;"></textarea> -->

<!-- <div>
  <button class="answerBTN" *ngIf="!showTextarea" (click)="toggleView()">Answer it</button>
  <mat-form-field name="mat-form-field-add-comment" id="mat-form-field-add-comment" *ngIf="showTextarea"
    [(ngModel)]="textAreaContent" (click)="toggleView()" class="custom-input textarea" floatLabel="never"
    floatPlaceholder="never">
    <textarea matInput rows="3" name="txTicketComment" id="txTicketComment" formControlName="txTicketComment"
      placeholder="Write Description" #txTicketComment></textarea>
  </mat-form-field>
</div> -->

<button class="primary-small-btn info-tooltip" placement="right" (click)="TransferAssigneeForHelpdesk()">Transfer Assignee</button>